import {
  DEFAULT_DROPZONE_OPTIONS,
  EXCEL_DROPZONE_OPTIONS,
} from '../constants/constants';
import { useAlertContext } from '../context';
import { useTranslatesContext } from '../context/TranslatesContext';

export const useDropzoneValidator = () => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  function nameLengthValidator(file: File) {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      onOpenAlert({ error: true, title: t('web_dropzone_error_format_file') });
    }
    if (file.size > DEFAULT_DROPZONE_OPTIONS.maxSize) {
      onOpenAlert({ error: true, title: t('web_dropzone_error_long_file') });
    }
    return null;
  }

  function excelFileValidator(file: File) {
    // Проверка типа файла
    if (
      file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'application/vnd.ms-excel'
    ) {
      onOpenAlert({ error: true, title: t('web_dropzone_error_format_file') });
      return {
        code: 'file-invalid-type',
        message: t('web_dropzone_error_format_file'),
      };
    }
    // Проверка размера файла
    if (file.size > EXCEL_DROPZONE_OPTIONS.maxSize) {
      onOpenAlert({ error: true, title: t('web_dropzone_error_long_file') });
      return {
        code: 'file-too-large',
        message: t('web_dropzone_error_long_file'),
      };
    }
    return null; // Если файл валиден
  }

  return { nameLengthValidator, excelFileValidator };
};
