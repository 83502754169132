import { Button, Stack, Typography } from '@mui/material';
import {
  ImportDishesError,
  useDishQuery,
  useImportDishesMutation,
} from '../../graphql/generated/graphql';
import { useAuthContext } from '../../context';
import { useParams, useSearchParams } from 'react-router-dom';
import { LINK_FOR_DOWNLOAD_EXCEL } from '../../constants/constants';
import { DropzoneRHF } from '../../components';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DISH_CATEGORIES } from '../../graphql/queries/dishCategories.gql';

type ExcelForm = {
  file: any;
};

export const AddOrUpdateDishMultiply = () => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const [errorAfterUploadExcel, setErrorAfterUploadExcel] = useState<
    ImportDishesError[] | []
  >([]);

  const [searchParams] = useSearchParams();
  const brandIdParams = searchParams.get('brandId');

  const { data: dishData } = useDishQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const brandId = user?.brand?.id || dishData?.dish.brandId || brandIdParams;

  const [importDishes, { loading }] = useImportDishesMutation({
    refetchQueries: [
      { query: DISH_CATEGORIES, variables: { brandId: Number(brandId) } },
    ],
    onCompleted: (data) => {
      if (data.importDishes?.errors && data.importDishes?.errors.length > 0) {
        setErrorAfterUploadExcel(data.importDishes.errors);
      }
    },
  });

  const methods = useForm<ExcelForm>({
    defaultValues: {
      file: null,
    },
  });

  const { handleSubmit, watch } = methods;

  const fileData = watch('file');

  useEffect(() => {
    setErrorAfterUploadExcel([]);
  }, [fileData]);

  const onSubmitRHF = (values: any) => {
    importDishes({
      variables: {
        file: values.file,
      },
    });
  };

  return (
    <Stack>
      <Stack sx={{ mt: '22px', gap: '22px' }}>
        <a href={LINK_FOR_DOWNLOAD_EXCEL} style={{ width: 'fit-content' }}>
          <Button variant={'outlined'}>
            Click here to download excel example
          </Button>
        </a>

        <FormProvider {...methods}>
          <Stack
            component={'form'}
            onSubmit={handleSubmit(onSubmitRHF)}
            sx={{ gap: '22px' }}
          >
            <DropzoneRHF name={`file`} isSmallLarge isExcelFile />
            <Button
              sx={{ width: 'fit-content' }}
              type={'submit'}
              disabled={!fileData || loading}
            >
              Send excel document
            </Button>
          </Stack>
        </FormProvider>
        <Stack sx={{ gap: '22px' }}>
          {errorAfterUploadExcel.map((err, index) => {
            return (
              <Stack key={index}>
                <Typography color={'error'}>
                  The fields for the dish "{err.title}" are filled out
                  incorrectly.
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
