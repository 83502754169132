import { useParams } from 'react-router-dom';
import { BackButton, MainLayout, PageTitleBlock } from '../../components';

import { useTranslatesContext } from '../../context/TranslatesContext';
import { AddOrUpdateDishSingle } from './AddOrUpdateDishSingle';
import { TabsCustom } from '../../components/TabsCustom/TabsCustom';
import { useState } from 'react';
import { AddOrUpdateDishMultiply } from './AddOrUpdateDishMultiply';

const tabOptions = [
  { id: 'single', label: 'Single' },
  { id: 'multiply', label: 'Multiply' },
];

export const AddOrUpdateDish = () => {
  const { id } = useParams();
  const { t } = useTranslatesContext();
  const [tabs, setTabs] = useState(tabOptions[0].id);

  const handleTabsClick = (value: string) => {
    setTabs(value);
  };

  return (
    <MainLayout>
      <BackButton />
      <PageTitleBlock
        title={
          !id ? t('web_add_dish_page_title') : t('web_edit_dish_page_title')
        }
        sx={{ marginTop: '10px' }}
      />
      <TabsCustom
        options={tabOptions}
        onClick={handleTabsClick}
        tabNum={tabs}
      />
      {tabs === tabOptions[0].id ? (
        <AddOrUpdateDishSingle />
      ) : (
        <AddOrUpdateDishMultiply />
      )}
    </MainLayout>
  );
};
