import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  BackButton,
  Button,
  DropzoneFormik,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  TextFieldFormik,
} from '../../components';
import { DollarIcon, TextIcon } from '../../components/Icons';
import {
  AddCategoryWrapperCSS,
  ButtonGroupCSS,
  CategoriesButtonCSS,
  FormWrapper,
  NutritionalFeaturesWrapperCSS,
} from './styles';
import { IFormikProps } from './types';
import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import {
  useAlertContext,
  useAuthContext,
  useModalContext,
} from '../../context';
import { MODAL_NAME } from '../../constants/constants';
import {
  useCreateDishMutation,
  useDishCategoriesQuery,
  useDishQuery,
  useUpdateDishMutation,
} from '../../graphql/generated/graphql';
import { useEffect, useState } from 'react';
import { addDish } from '../../validation';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useAccesses } from '../../hooks';

export const AddOrUpdateDishSingle = () => {
  const { id } = useParams();
  const { onOpenModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();
  const { user } = useAuthContext();
  const { t } = useTranslatesContext();
  const { isDishEditAccess } = useAccesses();

  const [searchParams] = useSearchParams();
  const brandIdParams = searchParams.get('brandId');

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  const {
    data: dishData,
    loading: dishLoading,
    error: dishError,
  } = useDishQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const brandId = user?.brand?.id || dishData?.dish.brandId || brandIdParams;

  const {
    data: dishCategories,
    loading: dishCategoriesLoading,
    error: dishCategoriesError,
  } = useDishCategoriesQuery({
    variables: { brandId: Number(brandId) },
    skip: !brandId,
  });

  const [createDish] = useCreateDishMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_add_dish') });
      navigate(-1);
    },
  });
  const [updateDish] = useUpdateDishMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_dish') });
      navigate(-1);
    },
  });

  const [initialValues, setInitialValues] = useState<IFormikProps>({
    name: '',
    categories: '',
    logo: null,
    description: '',
    price: '',
    time: '',
    personsCount: '',
    calories: '',
    weight: '',
    volume: '',
  });

  const handleSubmit = (values: IFormikProps) => {
    const formValues = {
      title: String(values.name),
      brandId: Number(brandId),
      dishCategoryId: Number(values.categories),
      image: typeof values.logo === 'string' ? null : values.logo,
      description: values.description,
      price: Number(values.price),
      cookingTime: Number(values.time),
      amountPersons: Number(values.personsCount),
      calories: Number(values.calories),
      weight: Number(values.weight),
      volume: Number(values.volume),
    };

    if (!id) {
      createDish({
        variables: {
          createDishInput: {
            ...formValues,
          },
        },
      });
    } else {
      updateDish({
        variables: {
          updateDishInput: {
            ...formValues,
            id: Number(dishData?.dish.id),
          },
        },
      });
    }
  };

  const handleOpenAddCategoryModal = () => {
    onOpenModal(MODAL_NAME.ADD_CATEGORY, { brandId });
  };
  const handleOpenategoriesModal = () => {
    onOpenModal(MODAL_NAME.CATEGORIES, { brandId });
  };

  const AddCategoryBtn = () => {
    return (
      <AddCategoryWrapperCSS onClick={handleOpenAddCategoryModal}>
        <Typography variant='body1' component='p' color={'primary.main'}>
          {t('web_add_dish_categories_add_btn')}
        </Typography>
      </AddCategoryWrapperCSS>
    );
  };

  const dishCategoriesOptions = !dishCategories
    ? []
    : dishCategories.dishCategories?.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  useEffect(() => {
    if (!isDishEditAccess) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (id && dishData) {
      const {
        title,
        dishCategoryId,
        image,
        description,
        price,
        cookingTime,
        amountPersons,
        calories,
        weight,
        volume,
      } = dishData.dish;

      setInitialValues({
        name: title,
        categories: String(dishCategoryId),
        logo: image.url,
        description: description,
        price: String(price),
        time: String(cookingTime),
        personsCount: String(amountPersons),
        calories: String(calories),
        weight: String(weight),
        volume: String(volume),
      });
    }
  }, [id, dishData]);

  return (
    <>
      <RequestHandler
        loading={dishCategoriesLoading || dishLoading}
        error={dishCategoriesError || dishError}
      >
        <Formik<IFormikProps>
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={addDish}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form>
                <FormWrapper>
                  <TextFieldFormik
                    name={'name'}
                    size={'large'}
                    label={t('web_add_dish_name_label')}
                    placeholder={t('web_add_dish_name_placeholder')}
                    mt='22px'
                  />

                  <SelectFormik
                    name={'categories'}
                    options={dishCategoriesOptions}
                    placeholder={t('web_add_dish_categories_placeholder')}
                    sx={{ marginTop: '24px' }}
                    label={t('web_add_dish_categories_label')}
                    ElementAfterOptions={AddCategoryBtn}
                  />
                  <CategoriesButtonCSS
                    variant='text'
                    onClick={handleOpenategoriesModal}
                  >
                    {t('web_add_dish_categories_edit_btn')}
                  </CategoriesButtonCSS>

                  <DropzoneFormik
                    label={t('web_dropzone_photo_logo')}
                    subLabel={t('web_add_dish_logo_sub_label')}
                    name={'logo'}
                    sx={{ marginTop: '24px' }}
                  />
                  <TextFieldFormik
                    name={'description'}
                    size={'large'}
                    label={t('web_add_dish_description_label')}
                    placeholder={t('web_add_dish_description_placeholder')}
                    multiline={true}
                    minRows={4}
                    mt='22px'
                  />
                  <TextFieldFormik
                    name={'price'}
                    size={'large'}
                    label={t('web_add_dish_price_label')}
                    placeholder={t('web_add_dish_price_placeholder')}
                    type='number'
                    Icon={DollarIcon}
                    mt='22px'
                    width='250px'
                  />
                  <TextFieldFormik
                    name={'time'}
                    size={'large'}
                    label={t('web_add_dish_time_label')}
                    placeholder={t('web_add_dish_time_placeholder')}
                    type='number'
                    Icon={() => (
                      <TextIcon text={t('web_add_dish_time_icon_label')} />
                    )}
                    mt='22px'
                    width='250px'
                  />
                  <TextFieldFormik
                    name={'personsCount'}
                    size={'large'}
                    label={t('web_add_dish_persons_label')}
                    placeholder={t('web_add_dish_persons_placeholder')}
                    mt='22px'
                    width='250px'
                    type='number'
                  />
                  <NutritionalFeaturesWrapperCSS>
                    <Box sx={{ width: '190px', marginRight: '12px' }}>
                      <TextFieldFormik
                        name={'calories'}
                        size={'large'}
                        label={t('web_add_dish_calories_label')}
                        placeholder={t('web_add_dish_calories_placeholder')}
                        type='number'
                        Icon={() => (
                          <TextIcon
                            text={t('web_add_dish_calories_icon_label')}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ width: '190px', marginRight: '12px' }}>
                      <TextFieldFormik
                        name={'weight'}
                        size={'large'}
                        label={t('web_add_dish_weight_label')}
                        placeholder={t('web_add_dish_weight_placeholder')}
                        type='number'
                        Icon={() => (
                          <TextIcon
                            text={t('web_add_dish_weight_icon_label')}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{ width: '190px', marginRight: '12px' }}>
                      <TextFieldFormik
                        name={'volume'}
                        size={'large'}
                        label={t('web_add_dish_volume_label')}
                        placeholder={t('web_add_dish_volume_placeholder')}
                        type='number'
                        Icon={() => (
                          <TextIcon
                            text={t('web_add_dish_volume_icon_label')}
                          />
                        )}
                      />
                    </Box>
                  </NutritionalFeaturesWrapperCSS>
                  <ButtonGroupCSS>
                    <Button
                      type={'submit'}
                      disabled={!brandId}
                      mt='24px'
                      width='fit-content'
                      height='50px'
                    >
                      {!id
                        ? t('web_add_dish_submit_btn')
                        : t('web_edit_dish_submit_btn')}
                    </Button>
                    <Button
                      mt='24px'
                      width='fit-content'
                      height='50px'
                      variant={'outlined'}
                      onClick={handleCancel}
                    >
                      {t('web_add_dish_action_cancel')}
                    </Button>
                  </ButtonGroupCSS>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      </RequestHandler>
    </>
  );
};
